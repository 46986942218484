import React from 'react';
import PDF from "../../assets/images/cateringMenu2.png"
const PdfViewerCater2 = () => {
  return (
    <div>
      <img src={PDF} className='h-auto' alt='snack board menu' />
    </div>
  );
};

export default PdfViewerCater2;
