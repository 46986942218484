import React from 'react';
import PDF from "../../assets/images/cateringMenu.png"
const PdfViewerCater = () => {
  return (
    <div>
      <img src={PDF} className='h-auto' alt='catering menu'/>
    </div>
  );
};

export default PdfViewerCater;
